import { useContext, useEffect } from "react"
import Link from "next/link";
import { UserContext } from "../UserContext";
import { useOrgTheme } from "../../context/ThemeContext";
const defaultLogoUrl = "/images/easy-quote-logo.png";

export default function NotFound() {
    const { getLogoUrl } =
    useOrgTheme();
  const logoUrl = getLogoUrl() || defaultLogoUrl;


    const {setHeaderShow, isStateNewJersey } = useContext(UserContext)

    useEffect(()=>{
        setHeaderShow(false);
        return ()=>{
            setHeaderShow(true);
        }
    },[])

    return (
        <div className="min-h-screen bg-white">
            <div className="mx-auto py-12 px-4 sm:px-6 ">
                <div className="mb-4 xxs:mb-8">
                {!isStateNewJersey && (<img src={logoUrl}
                        alt="logo"
                        className="object-contain cursor-pointer w-48 xxs:w-80 mx-auto" />)}
                        {isStateNewJersey && (<img src={logoUrl}
                        alt="logo"
                        className="object-contain cursor-pointer w-48 xxs:w-auto mx-auto" />)}
                </div>
                <div className="mx-auto w-full lg:max-w-xl container m-auto">
                    <div className="text-center font-primaryMedium">
                        <div className="sm:text-8xl text-7xl font-primaryBold text-bodyColor">404</div>
                        <h1 className="sm:text-3xl text-xl py-2 text-titleColor">Page Not Found</h1>
                        <p className="sm:text-xl xxs:text-lg text-base text-gray-700 leading-6">The page you are looking for does not seem to exist!</p>
                    </div>

                    <div
                        className="flex justify-center mt-6 px-6 md:px-8 pb-5 pt-5">
                       <Link href="/">
                         <button
                            className="py-3 px-8 text-sm font-primaryMedium rounded-md text-white text-sm xxs:text-base bg-primary hover:bg-opacity-80 focus:outline-none transition duration-250 ease-in-out">
                                Go back Home
                           </button>
                        </Link>
                    </div>


                </div>
            </div>

        </div>
    )
}
