import { useRouter } from "next/router";
import NotFound from "../components/Common/NotFound";
import { useOrgTheme } from "../context/ThemeContext";
import { useContext } from "react";
import { UserContext } from "../components/UserContext";
import GlobalLoader from "../components/Common/GlobalLoader";
const affiliatePagePath = "/affiliate"
export default function Custom404() {
  const { isThemeLoaded } = useOrgTheme();
  const { setloader } = useContext(UserContext);

  console.log("Custom404");
  const route = useRouter();
  const isAffiliatePage = () => {
    const isAffiliate =
      route.pathname.includes(affiliatePagePath) ||
      window?.location?.pathname.includes(affiliatePagePath);

    return isAffiliate;
  };
  if (!isThemeLoaded) {
    return <GlobalLoader />;
  }
  if (isAffiliatePage()) {
    setloader(true)
    const currentPath = window?.location?.pathname;
    const newPath = currentPath.replace(affiliatePagePath,"/ambassador")
    route.replace(newPath);
    return <></>;
  }
  return <NotFound />;
}
